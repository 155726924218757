<!--
 * @Description: 客服队列管理
 * @Author: ChenXueLin
 * @Date: 2021-09-15 15:35:49
 * @LastEditTime: 2022-07-26 14:06:48
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
      v-loading="loading"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="corpId">
              <e6-vr-select
                v-model="searchForm.corpId"
                :data="corpList"
                placeholder="客户名称"
                title="客户名称"
                clearable
                virtual
                remote
                :is-title="true"
                @filterChange="handleLoadCorpFilter"
                :props="{
                  id: 'corpId',
                  label: 'corpName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="corpPropIds">
              <e6-vr-select
                v-model="searchForm.corpPropIds"
                :data="corpAttrEnum"
                placeholder="客户属性"
                title="客户属性"
                :props="{
                  id: 'id',
                  label: 'attrValueDesc'
                }"
                multiple
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i title="设置先进队列" @click="addSetting">设置优先进入队列</i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          ref="tableList"
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <el-table-column
            label="序号"
            width="70"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 编辑弹框 -->
    <el-dialog
      v-dialogDrag
      title="设置优先进入队列"
      :visible.sync="setDialog"
      width="550px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      v-loading="setDialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="edit-customer-dialog"
    >
      <el-form
        ref="setForm"
        :model="setForm"
        :rules="setFormRules"
        lbel-width="120px"
      >
        <el-form-item label="优先进入客服队列" prop="ccic2QueueNo">
          <e6-vr-select
            style="width:300px"
            v-model="setForm.ccic2QueueNo"
            :data="qnoListEnum"
            placeholder="优先进入客服队列"
            title="优先进入客服队列"
            clearable
            :props="{
              id: 'qno',
              label: 'name'
            }"
          ></e6-vr-select>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import {
  getQueueList,
  getQnoList,
  getCorpAttrDownList,
  batchSetCustomerQueue
} from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "queueManageList",
  components: {},
  mixins: [listPage, listPageReszie, base],
  data() {
    return {
      loading: false,
      total: 0,
      searchForm: {
        corpId: "",
        corpPropIds: [],
        pageIndex: 1,
        pageSize: 20
      },
      columnData: [
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "客户名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpProp",
          display: true,
          fieldLabel: "客户属性",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "ccic2QueueNoName",
          display: true,
          fieldLabel: "优先进入队列",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "updatedByName",
          display: true,
          fieldLabel: "最后一次修改人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "updatedTime",
          display: true,
          fieldLabel: "最后一次修改时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      /***设置队列****/
      setDialog: false,
      setDialogLoading: false,
      setForm: {
        ccic2QueueNo: ""
      },
      corpIdList: [], //选中的客户
      qnoListEnum: [],
      setFormRules: {
        ccic2QueueNo: [
          {
            required: true,
            message: "请选择队列优先级",
            trigger: ["blur", "change"]
          }
        ]
      },
      corpAttrEnum: [],
      refTable: "tableList",
      queryListAPI: getQueueList
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getAttrList();

    this.getNoList();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    //客户下拉框处理逻辑
    handleLoadCorpFilter: _.debounce(async function(val) {
      if (val) {
        this.searchForm.corpId = "";
        this.loadCorpData(val);
      }
    }, 300),
    //客户属性下拉框
    async getAttrList() {
      try {
        let res = await getCorpAttrDownList({});
        //客户属性
        this.corpAttrEnum = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      }
    },
    //获取列表数据 这里只写了分页的 非分页的可自行在组件内覆盖
    async queryList() {
      try {
        let res = await this.queryListAPI(this.searchForm);
        this.tableData = res.data.array.map(item => {
          let qnoInfo = this.qnoListEnum.find(each => {
            return item.ccic2QueueNo == each.qno;
          });
          item.ccic2QueueNoName = qnoInfo && qnoInfo.name ? qnoInfo.name : "";
          return item;
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.elementTableDoLayout();
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 表格勾选时间
    handleSelectionChange(columns) {
      this.corpIdList = columns.map(item => {
        return item.corpId;
      });
    },
    addSetting() {
      if (!this.corpIdList.length) {
        this.$message.warning("请先勾选客户");
        return;
      }
      this.setDialog = true;
    },
    //获取队列下拉框
    async getNoList() {
      try {
        this.loading = true;
        let res = await getQnoList();
        this.qnoListEnum = this.getFreezeResponse(res, {
          path: "data"
        });
        this.queryList();
      } catch (error) {
        printError(error);
      }
    },
    //点击确定新增回访问题
    handleSubmit() {
      this.$refs.setForm.validate(valid => {
        if (valid) {
          this.handleQno();
        }
      });
    },
    async handleQno() {
      try {
        this.setDialogLoading = true;
        let res = await batchSetCustomerQueue({
          ...this.setForm,
          corpIds: this.corpIdList
        });
        if (res.code == "OK") {
          this.handleClose();
          this.queryList();
          this.$message.success("设置成功");
          this.corpIdList = [];
        }
      } catch (error) {
        printError(error);
      } finally {
        this.setDialogLoading = false;
      }
    },
    //关闭弹框置空数据
    handleClose() {
      this.setForm = {
        ccic2QueueNo: ""
      };
      this.$refs.setForm.resetFields();
      this.setDialog = false;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .edit-customer-dialog {
  .el-form {
    padding: 20px 20px 0;
    .block-form .el-form-item__label {
      float: none;
    }
    .options-content {
      display: block;
      width: 100%;
      margin-left: 15px;
      .options {
        font-size: 12px;
        color: #606266;
        display: flex;
        align-items: center;
        margin-top: 10px;
        i {
          margin-left: 10px;
          height: 28px;
          margin-right: 10px;
          color: #46bfea;
          cursor: pointer;
        }
        [class^="e6-icon"] {
          font-size: 20px;
        }
        .el-input {
          width: 300px;
          margin-left: 25px;
        }
      }
    }
  }
}
</style>
