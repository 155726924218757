var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-view-wrapper pd-10"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"sectionWrapper",staticClass:"content-view section-wrapper",attrs:{"element-loading-background":_vm.loadingBackground}},[_c('table-title',{on:{"resize":_vm.handleTableTitleResize},scopedSlots:_vm._u([{key:"searchForm",fn:function(){return [_c('el-form',{ref:"searchForm",staticClass:"search-list",attrs:{"model":_vm.searchForm}},[_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"corpId"}},[_c('e6-vr-select',{attrs:{"data":_vm.corpList,"placeholder":"客户名称","title":"客户名称","clearable":"","virtual":"","remote":"","is-title":true,"props":{
                id: 'corpId',
                label: 'corpName'
              }},on:{"filterChange":_vm.handleLoadCorpFilter},model:{value:(_vm.searchForm.corpId),callback:function ($$v) {_vm.$set(_vm.searchForm, "corpId", $$v)},expression:"searchForm.corpId"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"corpPropIds"}},[_c('e6-vr-select',{attrs:{"data":_vm.corpAttrEnum,"placeholder":"客户属性","title":"客户属性","props":{
                id: 'id',
                label: 'attrValueDesc'
              },"multiple":"","clearable":""},model:{value:(_vm.searchForm.corpPropIds),callback:function ($$v) {_vm.$set(_vm.searchForm, "corpPropIds", $$v)},expression:"searchForm.corpPropIds"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--buttons"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSearch}},[_vm._v("查询")]),_vm._v(" "),_c('el-button',{staticClass:"reset",on:{"click":_vm.handleReset}},[_vm._v("重置")])],1)],1)]},proxy:true},{key:"operateAction",fn:function(){return [_c('i',{attrs:{"title":"设置先进队列"},on:{"click":_vm.addSetting}},[_vm._v("设置优先进入队列")])]},proxy:true}])}),_vm._v(" "),_c('section',{staticClass:"table-wrapper"},[_c('el-table',{ref:"tableList",staticClass:"elTable",attrs:{"border":"","height":_vm.resizeViewHeight + 'px',"data":_vm.tableData,"highlight-current-row":""},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"40"}}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"序号","width":"70","fixed":"left","header-align":"center","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v("\n              "+_vm._s(scope.$index +
                  1 +
                  (_vm.searchForm.pageIndex - 1) * _vm.searchForm.pageSize)+"\n            ")])]}}])}),_vm._v(" "),_vm._l((_vm.columnData),function(column,index){return _c('el-table-column',{key:index,attrs:{"show-overflow-tooltip":"","prop":column.fieldName,"label":column.fieldLabel,"min-width":column.width,"fixed":column.fixed,"align":column.align,"header-align":"center"}})})],2)],1),_vm._v(" "),_c('section',{staticClass:"pagination-wrapper fixed-section"},[_c('el-pagination',{attrs:{"page-size":_vm.searchForm.pageSize,"current-page":_vm.searchForm.pageIndex,"page-sizes":_vm.pageSizes,"layout":_vm.layout,"total":_vm.total,"background":""},on:{"update:pageSize":function($event){return _vm.$set(_vm.searchForm, "pageSize", $event)},"update:page-size":function($event){return _vm.$set(_vm.searchForm, "pageSize", $event)},"update:currentPage":function($event){return _vm.$set(_vm.searchForm, "pageIndex", $event)},"update:current-page":function($event){return _vm.$set(_vm.searchForm, "pageIndex", $event)},"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1),_vm._v(" "),_c('el-dialog',{directives:[{name:"dialogDrag",rawName:"v-dialogDrag"},{name:"loading",rawName:"v-loading",value:(_vm.setDialogLoading),expression:"setDialogLoading"}],attrs:{"title":"设置优先进入队列","visible":_vm.setDialog,"width":"550px","before-close":_vm.handleClose,"close-on-click-modal":false,"element-loading-background":_vm.loadingBackground,"custom-class":"edit-customer-dialog"},on:{"update:visible":function($event){_vm.setDialog=$event}}},[_c('el-form',{ref:"setForm",attrs:{"model":_vm.setForm,"rules":_vm.setFormRules,"lbel-width":"120px"}},[_c('el-form-item',{attrs:{"label":"优先进入客服队列","prop":"ccic2QueueNo"}},[_c('e6-vr-select',{staticStyle:{"width":"300px"},attrs:{"data":_vm.qnoListEnum,"placeholder":"优先进入客服队列","title":"优先进入客服队列","clearable":"","props":{
            id: 'qno',
            label: 'name'
          }},model:{value:(_vm.setForm.ccic2QueueNo),callback:function ($$v) {_vm.$set(_vm.setForm, "ccic2QueueNo", $$v)},expression:"setForm.ccic2QueueNo"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"cancel",on:{"click":_vm.handleClose}},[_vm._v("取消")]),_vm._v(" "),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("确定")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }